/* eslint-disable no-restricted-imports */
import { Select, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, InputGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";
import { showErrorMessage } from "../../actions/notification";
import PageHeader from "../../common/antd/component/PageHeader";
import makeRequest from "../../libs/request";
import countries from "../common/countries.json";
import TinyMCEditor from "../common/TinyMCEditor";
import Loading from "../loading";
const { Option } = Select;

export default function DetailCompany(props) {
	// Example 1
	const [loading, setLoading] = useState({
		loadingProfile: false,
		loadingPage: true,
		loadingImage: false,
		loadingCertificate: false,
	});
	let [dataAdd, setData] = useState({ certificate_img: [] });
	const countries_list = countries.country;
	const { id } = props.match.params;
	const onSetLoading = (key, value) => {
		setLoading({
			...loading,
			[key]: value,
		});
	};

	const getCorporatesById = () => {
		makeRequest("get", `corporate/getCorporatesById`, { id })
			.then(({ data }) => {
				if (data.signal) {
					let item = data.data;
					let valid = item.mobile && isValidPhoneNumber(item.mobile) ? "true" : "false";
					if (valid === "false") {
						item.mobile = null;
					}
					item.root_email = item.root_admin ? item.root_admin.email : "";
					item.certificate_img = JSON.parse(item.certificate_img);
					dataAdd = {
						...item,
					};
					setData(dataAdd);
				} else {
					return showErrorMessage(data.message);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		getCorporatesById();
		onSetLoading("loadingPage", false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeValueEditor = (key, value, plain_text) => {
		setData({
			...dataAdd,
			[key]: value,
			plain_text,
		});
	};
	if (loading.loadingPage) {
		return <Loading />;
	}

	return (
		<>
			<div className="col-md-12">
				<PageHeader
					title={`Competency Entity Detail`}
					breadcrumb={[
						{ title: "Competency Entity Listing", link: "/company/list" },
						{ title: "Competency Entity Detail", link: "" },
					]}
				/>
			</div>
			{/* <div className="row">
				<div className="col-md-12">
					<div className="col-md-12">
						<TabCorporate id={id} tab={1} />
					</div>
				</div>
			</div> */}
			<Form>
				<div className="row">
					<div className="col-md-8">
						<div className="kt-section">
							<div className="kt-section__content">
								<div className="col-md-12">
									<Card>
										<Card.Body>
											<Card.Title style={{ fontWeight: "bold" }}>Individual/Company Details</Card.Title>
											<Form.Row>
												<Form.Group as={Col} controlId="formBasicName">
													<Form.Label className="starDanger">Company Name</Form.Label>
													<Form.Control
														type="text"
														autoFocus
														maxLength={255}
														className={dataAdd.err_name && "has-error"}
														placeholder="Enter company name"
														value={dataAdd.name || ""}
														disabled
													/>
												</Form.Group>
												<Form.Group as={Col} controlId="formBasicName">
													<Form.Label className="starDanger">Sign up as</Form.Label>
													<select className="form-control inline-block" disabled value={dataAdd.sign_up_as || ""}>
														<option value="Individual Expert">Individual Expert</option>
														<option value="Corporate">Corporate</option>
													</select>
												</Form.Group>
											</Form.Row>
											{dataAdd.sign_up_as === "Individual Expert" ? (
												<Form.Row>
													<Form.Group as={Col} controlId="formBasicName">
														<Form.Label className="starDanger">Individual Name</Form.Label>
														<Form.Control
															type="text"
															maxLength={255}
															className={dataAdd.err_individual_name && "has-error"}
															placeholder="Enter individual name"
															value={dataAdd.individual_name || ""}
														/>
													</Form.Group>
												</Form.Row>
											) : (
												""
											)}
											<Form.Row>
												<Form.Group as={Col} controlId="formBasicEmail">
													<Form.Label className="starDanger">
														<FormattedMessage id="PAGE.LABLE.COUNTRY" />
													</Form.Label>
													<Select
														disabled
														showSearch
														placeholder="Please select country"
														value={dataAdd.country}
														className={
															dataAdd.err_country
																? "has-error-select form-control select-user"
																: "form-control select-user"
														}
														suffixIcon={<img style={{ width: '12px', height: 'auto' }} src={'/images/button-icon/down-arrow.svg'} alt={'down-arrow'} />}
													>
														{countries_list.map((coun, key) => {
															return (
																<Option value={coun.countryName} key={`coun-${key}`}>
																	{coun.countryName}
																</Option>
															);
														})}
													</Select>
												</Form.Group>
												<Form.Group as={Col} controlId="formBasicMobile">
													<Form.Label className="starDanger">Main phone</Form.Label>
													<PhoneInput
														international
														countryCallingCodeEditable={false}
														className="form-control"
														flags={flags}
														placeholder="Enter phone number"
														defaultCountry="SG"
														value={dataAdd.mobile}
														disabled
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} controlId="formBasicName">
													<Form.Label className="starDanger" style={{ marginRight: 60 }}>
														<FormattedMessage id="PAGE.LABLE.ADDRESS" />
													</Form.Label>
													<Form.Control
														className={dataAdd.err_address && "has-error"}
														maxLength={255}
														placeholder="Enter address"
														value={dataAdd.address || ""}
														disabled
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} controlId="formBasicEmail">
													<Form.Label className="starDanger">Main email</Form.Label>
													<Form.Control
														type="email"
														className={dataAdd.err_email && "has-error"}
														maxLength={255}
														placeholder="Enter email"
														value={dataAdd.email || ""}
														disabled
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} controlId="formBasicName">
													<Form.Label className="starDanger" style={{ display: "flex", alignItems: "center" }}>
														<FormattedMessage id="PAGE.LABLE.SUB_DOMAIN" />
														<Popover
															placement="topLeft"
															content={
																<div>
																	Your website URL is: https://mytraining.center/
																	{dataAdd.sub_domain}
																</div>
															}
														>
															<InfoCircleOutlined style={{ paddingLeft: 5, cursor: "pointer" }} />
														</Popover>
													</Form.Label>
													<InputGroup>
														<Form.Control
															type="text"
															maxLength={255}
															className={dataAdd.err_sub_domain && "has-error"}
															placeholder="Enter sub domain"
															value={`mytraining.center/${dataAdd.sub_domain}` || ""}
															disabled
															style={{ background: "#eee", cursor: "pointer", borderRight: "none" }}
														/>
														<InputGroup.Append>
															<InputGroup.Text style={{ background: "#eeeeee" }}>
																{" "}
																<a href={`${process.env.REACT_APP_URL_FE}/${dataAdd.sub_domain}`} target="_blank">
																	Preview
																</a>{" "}
															</InputGroup.Text>
														</InputGroup.Append>
													</InputGroup>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} controlId="formBasicName">
													<Form.Label className="starDanger" style={{ marginRight: 60 }}>
														Description
													</Form.Label>
													<TinyMCEditor
														content={dataAdd.description}
														handleChange={onChangeValueEditor}
														name="description"
														id="description"
														disabled={true}
													/>
												</Form.Group>
											</Form.Row>
										</Card.Body>
									</Card>
									<Card style={{ marginTop: 10 }}>
										<Card.Body>
											<Card.Title style={{ fontWeight: "bold" }}>Personal Contact</Card.Title>
											<Form.Row>
												<Form.Group as={Col} controlId="formBasicName">
													<Form.Label className="starDanger">
														<FormattedMessage id="PAGE.LABLE.FIRST_NAME" />
													</Form.Label>
													<Form.Control
														type="text"
														maxLength={255}
														className={dataAdd.err_first_name && "has-error"}
														placeholder="Enter first name"
														value={dataAdd.person_first_name || ""}
														disabled
													/>
												</Form.Group>
												<Form.Group as={Col} controlId="formBasicName">
													<Form.Label className="starDanger">
														<FormattedMessage id="PAGE.LABLE.LAST_NAME" />
													</Form.Label>
													<Form.Control
														type="text"
														maxLength={255}
														className={dataAdd.err_last_name && "has-error"}
														placeholder="Enter last name"
														value={dataAdd.person_last_name || ""}
														disabled
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} controlId="formBasicEmail">
													<Form.Label className="starDanger">
														<FormattedMessage id="PAGE.LABLE.EMAIL_ADDRESS" />
													</Form.Label>
													<Form.Control
														type="email"
														className={dataAdd.err_email && "has-error"}
														maxLength={255}
														placeholder="Enter email"
														value={dataAdd.person_email || ""}
														disabled
													/>
												</Form.Group>
												<Form.Group as={Col} controlId="formBasicMobile">
													<Form.Label className="starDanger">
														<FormattedMessage id="PAGE.LABLE.MOBILE_NUMBER" />
													</Form.Label>
													<PhoneInput
														international
														countryCallingCodeEditable={false}
														className="form-control"
														flags={flags}
														placeholder="Enter phone number"
														defaultCountry="SG"
														value={dataAdd.person_mobile}
														disabled
														error={
															dataAdd.person_mobile
																? isValidPhoneNumber(dataAdd.person_mobile)
																	? undefined
																	: "Invalid phone number"
																: ""
														}
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} controlId="formBasicEmail">
													<Form.Label className="starDanger">
														<FormattedMessage id="PAGE.LABLE.DESIGNATION" />
													</Form.Label>
													<Form.Control
														type="text"
														className={dataAdd.err_designation && "has-error"}
														maxLength={255}
														placeholder="Enter Designation"
														value={dataAdd.person_designation || ""}
														disabled
													/>
												</Form.Group>
											</Form.Row>
										</Card.Body>
									</Card>
									<Card style={{ marginTop: 10 }}>
										<Card.Body>
											<Card.Title style={{ fontWeight: "bold" }}>Account Details</Card.Title>
											<Form.Row>
												<Form.Group as={Col} controlId="formBasicEmail">
													<Form.Label className="starDanger">
														<FormattedMessage id="PAGE.LABLE.EMAIL_ADDRESS" />
													</Form.Label>
													<Form.Control
														disabled
														type="email"
														className={dataAdd.err_email && "has-error"}
														maxLength={255}
														placeholder="Enter email"
														value={dataAdd.root_email || ""}
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col} controlId="formBasicName">
													<Form.Label className="starDanger">How do you plan to use your LMS?</Form.Label>
													<select className="form-control inline-block" disabled value={dataAdd.lms || ""}>
														<option value="">Choose option</option>
														<option value="Employee Learning and Development">Employee Learning and Development</option>
														<option value="Training of External Users">
															Training of External Users (eg. Customers, Vendors, Suppliers, etc,...)
														</option>
														<option value="Both Internal and External">Both Internal and External</option>
														<option value="Selling Courses">Selling Courses</option>
													</select>
												</Form.Group>
											</Form.Row>
										</Card.Body>
									</Card>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<Card>
							<Card.Body>
								<Card.Title style={{ fontWeight: "bold" }} className="starDanger">
									Logo
								</Card.Title>
								<Form.Row>
									<Form.Group as={Col} controlId="formBasicEmail">
										<img src={dataAdd.logo} alt="logo" />
									</Form.Group>
								</Form.Row>
							</Card.Body>
						</Card>
						<Card style={{ marginTop: 10 }}>
							<Card.Body>
								<Card.Title style={{ fontWeight: "bold" }} className="starDanger">
									Profile image
								</Card.Title>
								<Form.Row>
									<Form.Group as={Col} controlId="formBasicEmail">
										<img src={dataAdd.profile_img} alt="profile" />
									</Form.Group>
								</Form.Row>
							</Card.Body>
						</Card>
						<Card style={{ marginTop: 10 }}>
							<Card.Body>
								<Card.Title style={{ fontWeight: "bold" }} className="starDanger">{`Certificate & License`}</Card.Title>
								<Form.Row>
									<Form.Group as={Col} controlId="formBasicEmail">
										<div className="row">
											{dataAdd.certificate_img
												? dataAdd.certificate_img.map((img) => {
													return (
														<div key={img.uid} className="col-md-4 col-ms-6 col-xs-12">
															<img src={img.url} alt="certificate" />
														</div>
													);
												})
												: ""}
										</div>
									</Form.Group>
								</Form.Row>
							</Card.Body>
						</Card>
					</div>
				</div>
			</Form>
		</>
	);
}
