import moment from 'moment'
import MomentTimezone from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import ApexCharts from 'react-apexcharts'
import { ButtonComponent } from './ButtonComponent'

export const REMINDER_STATUS = {
  SNOOZED: '1',
  MISSED: '2',
  ACKNOWLEDGED: '3',
  NEW: '4',
  REMOVED: 'deleted',
}

const CurrentYearComponent = ({
  selectCertiLincen,
  isShow,
  tab,
  detailsList,
  certification,
}) => {
  const [series, setSeries] = useState([])
  const [labels, setLabels] = useState([])
  const [selectData, setSelectData] = useState()
  const [year, setYear] = useState(1)
  const [systemsTotal, setSystemsTotal] = useState(0)
  const [systemsActive, setSystemsActive] = useState(0)

  const getValueReminders = (reminders = [], selectDefault) => {
    let labelsList = []
    let seriesList = []
    reminders.map((item) => {
      labelsList.push(item?.name ?? '')
      let valid_from = MomentTimezone.tz(
        selectDefault?.valid_from,
        'DD/MM/YYYY',
        'Asia/Singapore'
      )
      let start_time = MomentTimezone.tz(
        item?.start_time,
        'DD/MM/YYYY HH:mm',
        'Asia/Singapore'
      )
      let totalDiff = start_time.diff(valid_from, 'minutes')
      let nowDiff = MomentTimezone.tz(moment(), 'Asia/Singapore').diff(
        valid_from,
        'minutes'
      )
      let percent = (nowDiff / totalDiff) * 100
      seriesList.push(percent >= 100 ? 0 : 100 - percent)
    })
    setLabels(labelsList)
    setSeries(seriesList)
  }

  useEffect(() => {
    let selectDefault = detailsList?.find((i, index) => {
      let isBetween = moment().isBetween(
        moment(i.valid_from, 'DD/MM/YYYY'),
        moment(i.valid_to, 'DD/MM/YYYY')
      )
      if (isBetween) {
        setYear(index + 1)
      }
      return isBetween
    })
    if (isShow && selectDefault) {
      let remindersList = []
      if (selectDefault?.reminders && selectDefault?.reminders?.length > 0) {
        let remindersListDraft = selectDefault?.reminders.filter(
          (i) =>
            i.status === REMINDER_STATUS.SNOOZED ||
            i.status === REMINDER_STATUS.NEW
        )
        remindersList = remindersListDraft.sort((a, b) => {
          return (
            moment(b.start_time, 'DD/MM/YYYY') -
            moment(a.start_time, 'DD/MM/YYYY')
          )
        })
      }
      setSelectData(selectDefault)
      getValueReminders(remindersList, selectDefault)
    }
  }, [detailsList, tab])

  useEffect(() => {
    if (certification?.systems) {
      let isActive = certification?.systems.filter(
        (item) => item?.final_audit && item?.final_audit?.is_ready === true
      )
      setSystemsTotal(certification?.systems?.length)
      setSystemsActive(isActive?.length)
    } else {
      setSystemsTotal(0)
      setSystemsActive(0)
    }
  }, [certification])

  let state2 = {
    series: series,
    options: {
      labels: labels,
      chart: {
        type: 'radialBar',
        height: 450,
      },
      colors: [
        '#FF443C',
        '#FF8800',
        '#FFCA41',
        '#A1DC67',
        '#02C038',
        '#29B9E6',
        '#338FEB',
        '#003AA5',
        '#9747FF',
        '#FF0492',
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        radialBar: {
          borderRadius: 30,
          dataLabels: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
        markers: { shape: 'circle' },
        fontSize: '14px',
        fontWeight: 400,
        labels: {
          colors: '#1F263E',
          useSeriesColors: false,
        },
      },
      stroke: {
        lineCap: 'round',
      },
    },
  }
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: '-35px',
          right: '0px',
        }}
      >
        {selectData?.valid_from && selectData?.valid_to && (
          <>
            <div className='rowy-center-end'>
              <div
                style={{
                  background: '#F4F7FE',
                  border: '1px solid #1F263E',
                  padding: '6px 12px',
                  borderRadius: '6px',
                }}
                className='f-medium_20'
              >
                (Y{year}) {selectData?.valid_from} - {selectData?.valid_to}
              </div>
            </div>
            <div className='rowy-center-end mt-16'>
              <ButtonComponent
                active={selectData?.final_audit?.is_ready}
                tooltipComponent={() => {
                  let time = ''
                  if (
                    selectData?.final_audit?.is_ready &&
                    selectData?.final_audit?.time
                  ) {
                    time = MomentTimezone.tz(
                      selectData?.final_audit?.time,
                      'HH:mm:ss DD/MM/YYYY',
                      'Asia/Singapore'
                    ).format('DD/MM/YYYY HH:mm:ss')
                    return (
                      <div className='f-regular_20'>
                        Ready for final audit on {time}
                      </div>
                    )
                  }
                  return (
                    <div className='f-regular_20'>
                      Record is not yet ready for audit
                    </div>
                  )
                }}
              />
            </div>
            <div className='rowy-center-end mt-16'>
              <ButtonComponent
                title='System'
                active={systemsTotal === systemsActive && systemsActive}
                tooltipComponent={() => {
                  let time = ''
                  if (systemsTotal === systemsActive && systemsActive) {
                    const latestData = certification?.systems.reduce(
                      (latest, current) => {
                        return MomentTimezone.tz(
                          latest?.final_audit?.time,
                          'HH:mm:ss DD/MM/YYYY',
                          'Asia/Singapore'
                        ) >
                          MomentTimezone.tz(
                            current?.final_audit?.time,
                            'HH:mm:ss DD/MM/YYYY',
                            'Asia/Singapore'
                          )
                          ? latest
                          : current
                      }
                    )
                    time = MomentTimezone.tz(
                      latestData?.final_audit?.time,
                      'HH:mm:ss DD/MM/YYYY',
                      'Asia/Singapore'
                    ).format('DD/MM/YYYY HH:mm:ss')
                    return (
                      <div className='f-regular_20'>
                        Ready for audit on {time} ({systemsActive}/
                        {systemsTotal})
                      </div>
                    )
                  }
                  return (
                    <div className='f-regular_20'>
                      System is not yet ready for audit ({systemsActive}/
                      {systemsTotal})
                    </div>
                  )
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className='apex-charts-row mb-32'>
        <ApexCharts
          options={state2.options}
          series={state2.series}
          type='radialBar'
          height={450}
        />
      </div>
    </>
  )
}

export default CurrentYearComponent
