import React from 'react'

const NoDataComponent = ({
  title = 'Oops, there is no data',
  minHeight = 'calc(100vh - 150px)',
  paddingTop = '24px',
  imgWidth = '352px',
}) => {
  return (
    <div
      style={{
        paddingTop: paddingTop,
      }}
    >
      <div
        className='row-center'
        style={{
          width: '100%',
          minHeight: minHeight,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          backgroundColor: 'white',
          borderRadius: '12px',
        }}
      >
        <div>
          <div className='row-center'>
            <img
              src='/images/no-data.svg'
              style={{
                width: imgWidth,
              }}
            />
          </div>
          <div className='f-semibold_30 black-color ml-50 mt-32'>{title}</div>
        </div>
      </div>
    </div>
  )
}

export default NoDataComponent
