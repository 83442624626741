/* eslint-disable no-mixed-operators */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { Avatar } from "antd";

class InquiryManagementNoti extends React.Component {
  render() {
    return (
      <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
        <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-profile">
          <Link to="/inquiry/list" className="link-inquiry">
            <div
              className="kt-header__topbar-user-2 border-radius-50"
              data-toggle="tooltip"
              data-placement="top"
              title="Inquiry Management"
            >
              <div className="border-radius-99 d-flex justify-content-center align-items-center notification-icon-2">
                <Avatar alt="send-2" src={"/images/notification-icon/send-2.svg"} style={{ width: "34px", height: "34px" }} />
              </div>
            </div>
          </Link>
        </Dropdown.Toggle>
      </Dropdown>
    );
  }
}

const mapStateToProps = (store) => ({
  menuConfig: store.builder.menuConfig,
});

export default connect(mapStateToProps)(InquiryManagementNoti);
