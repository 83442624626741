import React, { useEffect, useRef } from 'react'

const InfiniteScroll = ({
  children,
  loader,
  fetchMore,
  hasMore,
  endMessage,
  className,
}) => {
  const pageEndRef = useRef(null)
  useEffect(() => {
    if (hasMore) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // kiểm tra element có nằm trong viewport không?
          fetchMore()
        }
      })

      if (pageEndRef.current) {
        observer.observe(pageEndRef.current)
      }

      return () => {
        if (pageEndRef.current) {
          observer.unobserve(pageEndRef.current)
        }
      }
    }
  }, [hasMore])
  return (
    <div className={className}>
      {children}

      <div className='d-flex justify-content-center mt-8'>
        {hasMore ? <div ref={pageEndRef}>{loader}</div> : endMessage}
      </div>
    </div>
  )
}

export default InfiniteScroll
