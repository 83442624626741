import React from "react";

const CloseCircleFillIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_2986_20742)">
				<rect x="5" y="5" width="10" height="10" fill="white" />
				<path
					d="M9.47097 9.35216L10.0013 9.88249L10.5316 9.35215L12.3583 7.52546L12.4761 7.6433L10.6495 9.47L10.1191 10.0003L10.6495 10.5307L12.4761 12.3573L12.3583 12.4752L10.5316 10.6485L10.0013 10.1182L9.47098 10.6485L7.64428 12.4752L7.52643 12.3573L9.35313 10.5307L9.88347 10.0003L9.35313 9.47L7.52643 7.6433L7.64428 7.52545L9.47097 9.35216ZM0.917969 10.0003C0.917969 15.0169 4.98471 19.0837 10.0013 19.0837C15.0178 19.0837 19.0846 15.0169 19.0846 10.0003C19.0846 4.98373 15.0178 0.916992 10.0013 0.916992C4.98471 0.916992 0.917969 4.98374 0.917969 10.0003Z"
					fill="#768394"
					stroke="white"
					stroke-width="1.5"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2986_20742">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default CloseCircleFillIcon;
