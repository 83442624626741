/* eslint-disable no-restricted-imports */
import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import 'react-phone-number-input/style.css'
import { useLocation } from 'react-router-dom'
import { showErrorMessage } from '../../../actions/notification'
import checkPermission from '../../../libs/permission'
import YourInternalDocumentedComponent from './YourInternalDocumentedComponent'
import YourPublicReportComponent from './YourPublicReportComponent'

export default function YourAdministrationComponent({
  dataAdd,
  history,
  tab,
  id,
  show,
}) {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  let tabDraft = searchParams.get('tabChild') ?? 'your-internal-documented'
  const isFree = dataAdd?.plan_name === 'Free'
  let [tabChild, setTab] = useState(tabDraft)
  return (
    <>
      <Card className='mt-24'>
        <Card.Body
          className='pl-0 pr-0'
          style={{
            position: 'relative',
          }}
        >
          {isFree ? (
            <div
              style={{
                paddingTop: '24px',
              }}
            >
              <div
                className='row-center'
                style={{
                  width: '100%',
                  minHeight: 'calc(100vh - 300px)',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyItems: 'center',
                  backgroundColor: 'white',
                  borderRadius: '12px',
                }}
              >
                <div>
                  <div className='row-center'>
                    <img
                      src='/images/oops-2.svg'
                      style={{
                        width: '352px',
                      }}
                    />
                  </div>
                  <div
                    className='f-semibold_30 black-color ml-50 mt-32 row-center'
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    To access and experience these features, please kindly
                    upgrade to Pro Plan <br />
                    today !
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Tabs
                className='ant-tabs-nav-list-6'
                onChange={(value) => {
                  setTab(value)
                  history.push(
                    `/icpa/edit/${id}?idCompany=${dataAdd?._id}&tab=${tab}&tabChild=${value}`
                  )
                }}
                activeKey={tabChild}
              >
                <Tabs.TabPane
                  tab='Your Internal Documented'
                  key='your-internal-documented'
                >
                  <YourInternalDocumentedComponent
                    value={dataAdd}
                    id={dataAdd?._id}
                    show={tabChild === 'your-internal-documented'}
                    tab={tabChild}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Your Public Report' key='your-public-report'>
                  <YourPublicReportComponent
                    value={dataAdd}
                    id={dataAdd?._id}
                    show={tabChild === 'your-public-report'}
                    tab={tabChild}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  )
}
