import { AUTH_TOKEN_KEY } from "../config/auth";
const checkPermission = (key) => {
	let auth = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
	if (key === "dashboard") return true;
	if (!auth) {
		return false;
	}
	if ("user" in auth) {
		let profile = JSON.parse(auth.user);
		if (profile.role_id === 1) return true;
		if (!profile.is_manager && key === "marker") return true;
		if (!JSON.parse(localStorage.getItem("x-permission"))) {
			localStorage.setItem("x-permission", JSON.stringify(JSON.parse(auth.user).arrayPermissions));
		}
		let permission = JSON.parse(localStorage.getItem("x-permission"));
		if (Array.isArray(key)) {
			let found = key.find((val) => permission.includes(val));
			if (!found) return false;
		} else {
			let check = permission.indexOf(key);
			if (check === -1) return false;
		}
	}
	return true;
};

export default checkPermission;
