import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { AUTH_TOKEN_KEY } from "../../../app/config/auth";
import makeRequest from "../../../app/libs/request";

export default class MenuList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			profile: {},
			arrayPermissions: [],
			loading: true,
		};
	}
	componentDidMount() {
		if (localStorage.getItem(AUTH_TOKEN_KEY)) {
			let auth = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
			if (auth && auth.authToken) {
				const token = JSON.parse(auth.authToken);
				makeRequest("get", `user/userInfo?token=${token}`)
					.then(({ data }) => {
						if (data.signal) {
							this.setState({
								profile: data.data,
								arrayPermissions: data.data.arrayPermissions,
								loading: false,
							});
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	}
	checkPermission = (key) => {
		const { profile, arrayPermissions } = this.state;
		if (key === "dashboard") return true;
		if (profile.is_marker === 1) {
			if (key === "marker") {
				return true;
			}
			return false;
		} else if (key === "marker") return false;
		if (Array.isArray(key)) {
			let found = key.find((val) => arrayPermissions.includes(val));
			if (!found) return false;
		} else {
			let check = arrayPermissions.indexOf(key);
			if (check === -1) return false;
		}
		return true;
	};
	render() {
		const { currentUrl, menuConfig, layoutConfig, isAdmin, level3 } = this.props;
		const { loading, arrayPermissions } = this.state;
		if (loading) return null;
		return menuConfig.aside.items.map((child, index) => {
			if (child.hidden) {
				return <> </>;
			}
			return (
				<React.Fragment key={`menuList${index}`}>
					{child.section && <MenuSection item={child} />}
					{child.separator && <MenuItemSeparator item={child} />}
					{child.title && this.checkPermission(child.permission) && (
						<MenuItem
							item={child}
							arrayPermissions={arrayPermissions}
							currentUrl={currentUrl}
							layoutConfig={layoutConfig}
							isAdmin={isAdmin}
							level3={level3}
						/>
					)}
				</React.Fragment>
			);
		});
	}
}
