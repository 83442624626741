import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Button, InputLabel, TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { forgotPassword } from "../../crud/auth.crud";
import { showErrorMessage, showSuccessMessageIcon } from "../../actions/notification";

class ForgotPassword extends Component {
	state = { isRequested: false };

	render() {
		const { intl } = this.props;
		const { isRequested } = this.state;

		if (isRequested) {
			return <Redirect to="/auth" />;
		}

		return (
			<div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper" style={{ paddingTop: 185 }}>
				<div className="kt-login__body">
					<div className="kt-login__form">
						<div className="kt-login__title-2 mb-40">
							<h3>
								<FormattedMessage id="AUTH.FORGOT.TITLE" />
							</h3>
						</div>

						<Formik
							initialValues={{ email: "" }}
							validate={(values) => {
								const errors = {};

								if (!values.email) {
									errors.email = intl.formatMessage({
										id: "AUTH.VALIDATION.REQUIRED_FIELD",
									});
								} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
									errors.email = intl.formatMessage({
										id: "AUTH.VALIDATION.INVALID_FIELD",
									});
								}

								return errors;
							}}
							onSubmit={(values, { setStatus, setSubmitting }) => {
								// let hostFront = window.location.origin;
								forgotPassword(values.email)
									.then(({ data }) => {
										if (data.signal) {
											showSuccessMessageIcon("Send request success. Please check your email to authenticatie. Thank you.");
											this.setState({ isRequested: true });
										} else {
											setSubmitting(false);
											return showErrorMessage(data.message);
										}
									})
									.catch(() => {
										setSubmitting(false);
										setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.NOT_FOUND" }, { name: values.email }));
									});
							}}
						>
							{({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
								<form onSubmit={handleSubmit} className="kt-form">
									{status && (
										<div role="alert" className="alert alert-danger">
											<div className="alert-text">{status}</div>
										</div>
									)}

									<div className="form-group">
										<InputLabel>Email</InputLabel>
										<TextField
											variant="outlined"
											size="small"
											type="email"
											margin="normal"
											className="kt-width-full mt-0"
											name="email"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.email}
											helperText={touched.email && errors.email}
											error={Boolean(touched.email && errors.email)}
											autoFocus
										/>
									</div>

									<div className="kt-login__actions">
										<Link to="/auth">
											<button
												type="button"
												style={{
													width: "125px",
													height: "40px",
													color: "#3B445D",
													backgroundColor: "#EFF1F5",
													border: "none",
													padding: "0px 20px",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													borderRadius: "8px",
													fontSize: "16px",
													fontWeight: "500",
												}}
											>
												Back
											</button>
										</Link>

										<Button
											variant="contained"
											color="primary"
											type="submit"
											disabled={isSubmitting}
											className="btn-signin"
											style={{
												width: "125px",
												height: "40px",
											}}
										>
											Confirm
										</Button>
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		);
	}
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
