/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react'

import { Col, Row, Tag } from 'antd'
import moment from 'moment'
import { showErrorMessage } from '../../../actions/notification'
import UploadFileComponent from '../../../common/antd/component/upload/UploadFileComponent'
import makeRequest from '../../../libs/request'
import NoDataComponent from './NoDataComponent'

export const CategoryComponent = ({
  value,
  defaultShow = false,
  isInternal,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(defaultShow)
  const [documentList, setDocumentList] = useState([])
  const getCompanyInfo = async () => {
    setIsLoading(true)
    if (isInternal) {
      makeRequest('get', `icpa/internal-reports/report/get-all/${value?._id}`)
        .then(({ data }) => {
          setIsLoading(false)
          if (data?.signal) {
            let itemList = data?.data
            if (itemList && itemList?.length > 0 && itemList[0]) {
              let list = itemList.map((i) => {
                return {
                  ...i,
                  url: i.file_url,
                  year: i.date_of_last_reviewed,
                }
              })
              setDocumentList([...list])
            } else {
              setDocumentList([])
            }
          } else {
            return showErrorMessage(data.message)
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    } else {
      makeRequest('get', `icpa/public-reports/report/get-all/${value?._id}`)
        .then(({ data }) => {
          setIsLoading(false)
          if (data?.signal) {
            let itemList = data?.data
            if (itemList && itemList?.length > 0 && itemList[0]) {
              let list = itemList.map((i) => {
                return {
                  ...i,
                  url: i.file_url,
                  year: i.date_of_publication,
                }
              })
              setDocumentList([...list])
            } else {
              setDocumentList([])
            }
          } else {
            return showErrorMessage(data.message)
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err)
        })
    }
  }

  useEffect(() => {
    if (value?._id && show) {
      getCompanyInfo()
    }
  }, [value, show])

  let renderStatus = (status) => {
    if (status === 'published') {
      return (
        <Tag bordered={false} className='ml-8 tag-marking-progress-2 mr-0'>
          Published
        </Tag>
      )
    } else if (status === 'unpublished') {
      return (
        <Tag bordered={false} className='ml-8 tag-draft mr-0'>
          Unpublished
        </Tag>
      )
    }
  }

  return (
    <>
      <Row
        gutter={24}
        style={{
          border: '1px solid #DDDDDD',
          borderRadius: '6px',
          width: '100%',
          marginLeft: '0px',
          marginRight: '0px',
        }}
        className='mt-16'
      >
        <div
          style={{
            width: '100%',
          }}
        >
          <div
            className='d-flex'
            style={{
              background: '#F4F7FE',
              borderRadius: '6px',
              width: '100%',
              height: '54px',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
            onClick={() => {
              setShow(!show)
            }}
          >
            <div className='f-semibold_24 black-color rowy-center width-full pl-16'>
              {value?.name}
            </div>
            <div className='row-center'>
              <div
                className='row-center'
                style={{
                  width: '36px',
                  minWidth: '36px',
                  height: '54px',
                  borderRadius: '6px',
                  backgroundColor: '#9747FF',
                  color: '#FFFFFF',
                }}
              >
                <div
                  className='row-center'
                  style={{
                    transform: show ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                >
                  <svg
                    width='14'
                    height='8'
                    viewBox='0 0 14 8'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.99998 2.90905L1.55556 8L0 6.54547L6.99998 0L14 6.54547L12.4445 8L6.99998 2.90905Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          {show && (
            <Row gutter={24} className='mt-8 p-16'>
              {documentList?.length > 0 ? (
                documentList.map((i, index) => {
                  return (
                    <Col
                      key={index}
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                    >
                      <div>
                        <div className=' d-flex align-items-center justify-content-between mt-8'>
                          <div
                            style={{
                              fontSize: '14px',
                              fontWeight: '600',
                              color: '#1F263E',
                            }}
                            className='mb-8 rowy-center '
                          >
                            {i?.name}
                          </div>
                          {renderStatus(i.type)}
                        </div>
                        <UploadFileComponent
                          value={i}
                          label={`License `}
                          type={'license'}
                          isDeleteFile={true}
                          disabled={true}
                          isNewReview={true}
                          isDocument={true}
                          year={
                            i?.year ? moment(i?.year).format('DD/MM/YYYY') : ''
                          }
                        />
                      </div>
                    </Col>
                  )
                })
              ) : isLoading ? (
                <div
                  style={{
                    minHeight: '400px',
                  }}
                ></div>
              ) : (
                <div
                  style={{
                    width: '100%',
                  }}
                  className='row-center'
                >
                  <NoDataComponent minHeight={'400px'} />
                </div>
              )}
            </Row>
          )}
        </div>
      </Row>
    </>
  )
}
