import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Button, InputLabel, TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { confirmForgotPassword } from "../../crud/auth.crud";
import { showErrorMessage, showSuccessMessageIcon } from "../../actions/notification";

function ConfirmForgotPassword(props) {
	const [isRequested, setRequested] = useState(false);
	const { intl } = props;
	const passRef = React.createRef();
	const rePassRef = React.createRef();
	if (isRequested) {
		return <Redirect to="/auth" />;
	}

	return (
		<div
			className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
			style={{ paddingTop: 185 }}
		>
			<div className="kt-login__body">
				<div className="kt-login__form">
					<div className="kt-login__title-2 mb-40">
						<h3>
							<FormattedMessage id="AUTH.FORGOT.CONFIRM" />
						</h3>
					</div>

					<Formik
						initialValues={{ password: "", rePassword: "" }}
						onSubmit={(values, { setStatus, setSubmitting }) => {
							if (!values.password) {
								setSubmitting(false);
								passRef.current.focus();
								return showErrorMessage("Please enter new password.");
							} else {
								if (values.password.length < 6) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage("Password must be at least 6 characters.");
								}
								if (values.password.length > 20) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage("Password must be longer than 20 characters.");
								}
								const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/;
								if (!regex.test(values.password)) {
									setSubmitting(false);
									passRef.current.focus();
									return showErrorMessage(
										"Password contains at least a upper case, lower case, symbol and a number. Except space character"
									);
								}
							}

							if (!values.rePassword) {
								setSubmitting(false);
								rePassRef.current.focus();
								return showErrorMessage("Please confirm new password.");
							}

							if (values.password !== values.rePassword) {
								setSubmitting(false);
								passRef.current.focus();
								return showErrorMessage("Invalid. Password and re-password do not match.");
							}

							// let email = props.match.params.email;
							let strConfirm = props.match.params.strConfirm;

							confirmForgotPassword(strConfirm, values.password)
								.then(({ data }) => {
									if (data.signal) {
										if (data.data === 1) {
											showSuccessMessageIcon("Change password success. Please use new password to login system.");
											setRequested(true);
										} else if (data.data === 2) {
											setRequested(true);
											return showErrorMessage("Request failed because version validation has expired");
										} else if (data.data === 3) {
											setRequested(true);
											return showErrorMessage("Not found user.");
										} else {
											setRequested(true);
											return showErrorMessage("Opps! Something was wrong!");
										}
									} else {
										setRequested(false);
										return showErrorMessage(data.message);
									}
								})
								.catch(() => {
									setSubmitting(false);
									setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.NOT_FOUND" }, { name: values.password }));
								});
						}}
					>
						{({ values, status, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
							<form onSubmit={handleSubmit} className="kt-form-2">
								{status && (
									<div role="alert" className="alert alert-danger">
										<div className="alert-text">{status}</div>
									</div>
								)}

								<div className="form-group">
									<InputLabel>Password</InputLabel>
									<TextField
										variant="outlined"
										size="small"
										type="password"
										margin="normal"
										className="kt-width-full mt-0"
										name="password"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.password}
										autoFocus
										inputRef={passRef}
									/>
								</div>

								<div className="form-group">
									<InputLabel>Re-password</InputLabel>
									<TextField
										variant="outlined"
										size="small"
										type="password"
										margin="normal"
										className="kt-width-full mt-0"
										name="rePassword"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.rePassword}
										inputRef={rePassRef}
									/>
								</div>

								<div className="kt-login__actions">
									<Link to="/auth">
										<button
											type="button"
											style={{
												width: "125px",
												height: "40px",
												color: "#3B445D",
												backgroundColor: "#EFF1F5",
												border: "none",
												padding: "0px 20px",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												borderRadius: "8px",
												fontSize: "16px",
												fontWeight: "500",
											}}
										>
											Back
										</button>
									</Link>

									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={isSubmitting}
										className="btn-signin"
										style={{
											width: "125px",
											height: "40px",
										}}
									>
										Confirm
									</Button>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(connect(null, auth.actions)(ConfirmForgotPassword));
