/* eslint-disable no-mixed-operators */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { Avatar } from "antd";

class UserProfile extends React.Component {
	render() {
		const { user, showHi, showAvatar, isNewUI } = this.props;
		return (
			<Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
				<Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-profile">
					{isNewUI ? (
						<div
							className="kt-header__topbar-user-2 border-radius-50"
							data-toggle="tooltip"
							data-placement="top"
							title="User"
						>
							{showAvatar && <Avatar alt="Avatar" src={(user.avatar && user.avatar) || "/media/users/default.jpg"} style={{ width: "44px", height: "44px" }} />}
						</div>
					) : (
						<div className="kt-header__topbar-user">
							{showHi && <span className="kt-header__topbar-welcome kt-hidden-mobile">Hi,</span>}

							{showHi && <span className="kt-header__topbar-username kt-hidden-mobile">{user.first_name}</span>}

							{showAvatar && <img alt="Avatar" src={(user.avatar && user.avatar) || "/media/users/default.jpg"} style={{ width: "34px", height: "34px" }} />}
						</div>
					)}
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl dropdown-menu-customer-2">
					<Link to="/logout">
						<div className="w-100 h-50 d-flex justify-content-center align-items-center f-medium_24 primary-color">
							Logout
						</div>
					</Link>
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

const mapStateToProps = ({ auth: { user } }) => ({
	user,
});

export default connect(mapStateToProps)(UserProfile);
