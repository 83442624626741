import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
// import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ConfirmForgotPassword from "./ConfirmForgotPassword";
import ActiveAccount from "./ActiveAccount";
import ActiveMarker from "./ActiveMarker";
import "./authStyle.scss";

export default function AuthPage() {
	return (
		<>
			<div className="kt-grid kt-grid--ver kt-grid--root">
				<div id="kt_login" className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1">
					<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
						<div
							className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside img-center login-background-image"
						>
							<Link to="/" style={{ height: "100%", width: "100%" }}>
							</Link>
						</div>

						<div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper box-right">
							<Switch>
								<Route path="/auth/login" component={Login} />
								{/* <Route path="/auth/registration" component={Registration} /> */}
								<Route path="/auth/forgotPassword" component={ForgotPassword} />
								<Route path="/auth/confirmForgot/:strConfirm" component={ConfirmForgotPassword} />
								<Route path="/auth/active/:strConfirm" component={ActiveAccount} />
								<Route path="/auth/active-marker/:strConfirm" component={ActiveMarker} />
								<Redirect from="/auth" exact={true} to="/auth/login" />
								<Redirect to="/auth/login" />
							</Switch>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
