/* eslint-disable no-lone-blocks */
import React, { Suspense, lazy, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { AUTH_TOKEN_KEY } from "../../config/auth";
import makeRequest from "../../../app/libs/request";
import ListCompany from "../company/ListCompany";
import CommercialPartner from "../company/CommercialPartner";
import DetailCompany from "../company/DetailCompany";
import ListICPA from "../icpa/ListIcpa";
import EditICPA from "../icpa/EditICPA";
import ListIcpaTransaction from "../icpa-transaction/ListIcpaTransaction";
{
	/* =============Dashboard============= */
}
const Dashboard = lazy(() => import("../dashboard/Dashboard"));

const Error403 = lazy(() => import("../common/Error403"));
{
	/* ==================================== */
}
const ListCategory = lazy(() => import("../category/ListCategory"));
const ListLevel = lazy(() => import("../level/ListLevel"));
const ListDepartment = lazy(() => import("../department/ListDepartment"));
const ListCourse = lazy(() => import("../course/ListCourse"));
const ListCourseOnsite = lazy(() => import("../course/ListCourseOnsite"));
const Attended = lazy(() => import("../course/Attended"));
const AnalyticExam = lazy(() => import("../course/AnalyticExam"));
const PreviewExam = lazy(() => import("../course/preview/PreviewExam"));
const CreateCourse = lazy(() => import("../course/CreateCourse"));
const EditCourse = lazy(() => import("../course/EditCourse"));
const Analytics = lazy(() => import("../course/Analytics"));
const ExamResponse = lazy(() => import("../course/response/Exam"));
const DetailResponse = lazy(() => import("../course/response/Detail"));
const ListUser = lazy(() => import("../users/eamd/ListUser"));
const CreateUser = lazy(() => import("../users/eamd/CreateUser"));
const EditUser = lazy(() => import("../users/eamd/EditUser"));
const ListMarker = lazy(() => import("../users/marker/ListUser"));
const CreateMarker = lazy(() => import("../users/marker/CreateUser"));
const EditMarker = lazy(() => import("../users/marker/EditUser"));
const EditRowCorporateUser = lazy(() => import("../corporate/EditRowCorporateUser"));
const ListPromotion = lazy(() => import("../promotions/ListPromotion"));
const CreatePromotion = lazy(() => import("../promotions/CreatePromotion"));
const EditPromotion = lazy(() => import("../promotions/EditPromotion"));
const ListOrder = lazy(() => import("../orders/ListOrder"));
const ViewOrder = lazy(() => import("../orders/ViewOrder"));
const Revenue = lazy(() => import("../orders/Revenue"));
const ListInquiry = lazy(() => import("../inquiry/ListInquiry"));
const ViewInquiry = lazy(() => import("../inquiry/ViewInquiry"));
const ListTranning = lazy(() => import("../trannings/ListTranning"));
const CreateTranning = lazy(() => import("../trannings/CreateTranning"));
const EditTranning = lazy(() => import("../trannings/EditTranning"));
const ListCorporate = lazy(() => import("../corporate/ListCorporate"));
const CreateCorporate = lazy(() => import("../corporate/CreateCorporate"));
const EditCorporate = lazy(() => import("../corporate/EditCorporate"));
const DetailCorporate = lazy(() => import("../corporate/DetailCorporate"));
const EditCorporateCourse = lazy(() => import("../corporate/EditCorporateCourse"));
const EditCorporateDepartment = lazy(() => import("../corporate/EditCorporateDepartment"));
const CreateCorUserTab = lazy(() => import("../corporate/CreateCorUserTab"));
const EditCorporateUser = lazy(() => import("../corporate/EditCorporateUser"));
const CorporatePaymentTab = lazy(() => import("../corporate/CorporatePaymentTab"));
const CorporateBillingList = lazy(() => import("../corporate/BillingList"));
const CorStorageDetail = lazy(() => import("../corporate/StorageDetail"));
const ListCB = lazy(() => import("../cb/ListCB"));
const CreateCB = lazy(() => import("../cb/CreateCB"));
const EditCB = lazy(() => import("../cb/EditCB"));
const DetailCB = lazy(() => import("../cb/DetailCB"));
const EditCBCourse = lazy(() => import("../cb/EditCBCourse"));
const TransferLog = lazy(() => import("../cb/TransferLog"));
const ListCorporateOfCB = lazy(() => import("../cb/ListCorporate"));
const DetailCorporateCB = lazy(() => import("../cb/DetailCorporate"));
const UsersCorporateCB = lazy(() => import("../cb/UsersCorporateCB"));
const DetailUserCE = lazy(() => import("../cb/DetailUserCE"));
const EditCBAdminList = lazy(() => import("../cb/EditCBAdminList"));
const EditCBUserList = lazy(() => import("../cb/EditCBUserList"));
const DetailUser = lazy(() => import("../cb/DetailUser"));
const CreateCBUserTab = lazy(() => import("../cb/CreateCBUserTab"));
const EditRowCBUser = lazy(() => import("../cb/EditRowCBUser"));
const EditCBConfig = lazy(() => import("../cb/EditCBConfig"));
const CBPaymentTab = lazy(() => import("../cb/CBPaymentTab"));
const CBBillingList = lazy(() => import("../cb/BillingList"));
const StorageDetail = lazy(() => import("../cb/StorageDetail"));
const ListMass = lazy(() => import("../mass/ListMass"));
const CreateMass = lazy(() => import("../mass/CreateMass"));
const ViewMass = lazy(() => import("../mass/ViewMail"));
const UseMail = lazy(() => import("../mass/UseMail"));
const EditMass = lazy(() => import("../mass/EditMass"));
const ListAdmin = lazy(() => import("../admin/ListUser"));
const CreateAdmin = lazy(() => import("../admin/CreateUser"));
const EditAdmin = lazy(() => import("../admin/EditUser"));
const Report = lazy(() => import("../report"));
const Agent = lazy(() => import("../agent/ListAgent"));
const InviteAgent = lazy(() => import("../agent/InviteAgent"));
const EditAgent = lazy(() => import("../agent/EditAgent"));
const ListExam = lazy(() => import("../quiz/ListExam"));
const CreateExam = lazy(() => import("../quiz/CreateExam"));
const EditExam = lazy(() => import("../quiz/EditExam"));
const ListPlan = lazy(() => import("../plan/ListPlan"));
const CreatePlan = lazy(() => import("../plan/CreatePlan"));
const EditPlan = lazy(() => import("../plan/EditPlan"));
const ListFeature = lazy(() => import("../plan/feature/ListFeature"));
const ListStorage = lazy(() => import("../storages"));
const CreateItem = lazy(() => import("../storages/CreateItem"));
const EditItem = lazy(() => import("../storages/EditItem"));
// const ListAds = lazy(() => import("../ads/ListAds"));
// const CreateAds = lazy(() => import("../ads/CreateAds"));
// const EditAds = lazy(() => import("../ads/EditAds"));
const SaleReport = lazy(() => import("../report/sale/SaleReport"));
const CreateBuilder = lazy(() => import("../course-builder/CreateBuilder"));
const ListBuilder = lazy(() => import("../course-builder/ListBuilder"));
const UpdateBuilder = lazy(() => import("../course-builder/UpdateBuilder"));
const PreviewBuilder = lazy(() => import("../course-builder/PreviewBuilder"));
const ListQuestionBank = lazy(() => import("../question-bank/ListQuestion"));
const CreateQuestion = lazy(() => import("../question-bank/CreateQuestion"));
const EditQuestion = lazy(() => import("../question-bank/EditQuestion"));
const DashboardMarker = lazy(() => import("../dashboard/DashboardMarker"));
const DetailProgress = lazy(() => import("../dashboard/DetailProgress"));
const PreviewQuiz = lazy(() => import("../quiz/Preview"));
const ListRole = lazy(() => import("../roles/ListRole"));
const EditRole = lazy(() => import("../roles/EditRole"));
const CreateRole = lazy(() => import("../roles/CreateRole"));
const Setting = lazy(() => import("../home/Setting"));
const ReferralList = lazy(() => import("../referral/ListReferral"));

export default function HomePage() {
	const [isAdmin, setIsAdmin] = useState(true);
	// const [role, setRole] = useState("");
	setTimeout(() => {
		if (localStorage.getItem(AUTH_TOKEN_KEY)) {
			let auth = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
			if (auth && auth.authToken) {
				const token = JSON.parse(auth.authToken);
				makeRequest("get", `user/userInfo?token=${token}`)
					.then(({ data }) => {
						if (data.signal) {
							setIsAdmin(data.data.is_manager);
							// setRole(data.data.role_admin);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	}, 500);
	if (!isAdmin) {
		return (
			<Suspense fallback={<LayoutSplashScreen />}>
				<Switch>
					{
						/* Redirect from root URL to /dashboard. */
						<Redirect exact from="/" to="/dashboard" />
					}
					{/* Route other */}
					<Route path="/dashboard" component={DashboardMarker} />
					{/* <Redirect to="Error403" /> */}
					<Route path="/Error403" component={Error403} />
					<Route path="/detail-progress/:id" component={DetailProgress} />
				</Switch>
			</Suspense>
		);
	}
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					/* Redirect from root URL to /dashboard. */
					<Redirect exact from="/" to="/dashboard" />
				}
				{/* Route other */}
				<Route path="/dashboard" component={Dashboard} />
				{/* <Redirect to="Error403" /> */}
				<Route path="/Error403" component={Error403} />
				{/* <Redirect to="TestMe" /> */}
				<Route path="/industry/list" component={ListCategory} />
				<Route path="/level/list" component={ListLevel} />
				<Route path="/courses/list-online" component={ListCourse} />
				<Route path="/courses/list-onsite" component={ListCourseOnsite} />
				<Route path="/course/create" component={CreateCourse} />
				<Route path="/course/edit/:id" component={EditCourse} />
				<Route path="/course/view/:id/analytic-exam/:exam_id" component={AnalyticExam} />
				<Route path="/course/view/:id/preview-exam/:exam_id/version/:version_id" component={PreviewExam} />
				<Route path="/course/attended/:id" component={Attended} />
				<Route path="/course/analytics" component={Analytics} />
				<Route path="/course/:id/user/:user_id" component={ExamResponse} />
				<Route path="/course/:id/user-data/:user_id/item/:progress_id" component={DetailResponse} />
				<Route path="/users/list" component={ListUser} />
				<Route path="/users/create" component={CreateUser} />
				<Route path="/users/edit/:id" component={EditUser} />
				<Route path="/promotion/list" component={ListPromotion} />
				<Route path="/promotion/create" component={CreatePromotion} />
				<Route path="/promotion/edit/:id" component={EditPromotion} />
				{/* <Route path="/promotion/view/:id" component={ViewPromotion} /> */}
				<Route path="/order/list" component={ListOrder} />
				<Route path="/order/view/:id" component={ViewOrder} />
				<Route path="/company-revenue" component={Revenue} />
				<Route path="/inquiry/list" component={ListInquiry} />
				<Route path="/inquiry/view/:id" component={ViewInquiry} />
				<Route path="/training/list" component={ListTranning} />
				<Route path="/training/create" component={CreateTranning} />
				<Route path="/training/edit/:id" component={EditTranning} />
				<Route path="/corporate/list" component={ListCorporate} />
				<Route path="/company/list" component={ListCompany} />
				<Route path="/commercial-partners" component={CommercialPartner} />
				<Route path="/company/detail/:id" component={DetailCompany} />
				<Route path="/corporate/create" component={CreateCorporate} />
				<Route path="/corporate-tab/edit/:id" component={EditCorporate} />
				<Route path="/corporate-tab/detail/:id" component={DetailCorporate} />
				<Route path="/corporate-course-tab/edit/:id" component={EditCorporateCourse} />
				<Route path="/department-tab/edit/:id" component={EditCorporateDepartment} />
				<Route path="/corporate-user-tab/create/:id" component={CreateCorUserTab} />
				<Route path="/corporate-user-tab/edit/:id" component={EditCorporateUser} />
				<Route path="/corporate-user-row-tab/edit/:corporate_id/user/:id" component={EditRowCorporateUser} />
				<Route path="/corporate-payment-tab/edit/:id" component={CorporatePaymentTab} />
				<Route path="/corporate-billing/:id" component={CorporateBillingList} />
				<Route path="/corporate-storage/:id" component={CorStorageDetail} />
				<Route path="/icpa/list" component={ListICPA} />
				<Route path="/icpa/edit/:id" component={EditICPA} />
				<Route path="/icpa-transaction/list" component={ListIcpaTransaction} />
				<Route path="/certification-body/list" component={ListCB} />
				<Route path="/certification-body/create" component={CreateCB} />
				<Route path="/certification-tab/edit/:id" component={EditCB} />
				<Route path="/certification-tab/detail/:id" component={DetailCB} />
				<Route path="/certification-course-tab/edit/:id" component={EditCBCourse} />
				<Route path="/certification-config-tab/edit/:id" component={EditCBConfig} />
				<Route path="/certification-admin-tab/edit/:id" component={EditCBAdminList} />
				<Route path="/certification-user-tab/edit/:id" component={EditCBUserList} />
				<Route path="/certification-tab/competency-entity/:id" component={ListCorporateOfCB} />
				<Route path="/certification-tab/ce/:cb_id/detail/:id" component={DetailCorporateCB} />
				<Route path="/certification-tab/ce/:cb_id/users/:id" component={UsersCorporateCB} />
				<Route path="/certification-tab/ce-users/:cb_id/ce/:ce_id/detail/:id" component={DetailUserCE} />
				{/* <Route path="/cb-promotion-tab/edit/:id" component={EditCBPromotion} /> */}
				{/* <Route path="/cb-promotion-row/edit/:cb_id/code/:promotion_id" component={EditCBPromotionRow} /> */}
				{/* <Route path="/cb-promotion-row/create/:cb_id" component={CreaeteCBPromotionRow} /> */}
				{/* <Route path="/cb-promotion-row/edit/:cb_id/code/:promotion_id" component={EditCBPromotionRow} /> */}
				<Route path="/certification-admin-tab/create/:id" component={CreateCBUserTab} />
				<Route path="/certification-admin-row/edit/:cb_id/user/:id" component={EditRowCBUser} />
				<Route path="/certification-user-row/detail/:cb_id/user/:id" component={DetailUser} />
				<Route path="/certification-payment-tab/edit/:id" component={CBPaymentTab} />
				<Route path="/certification-billing/:id" component={CBBillingList} />
				<Route path="/certification-storage/:id" component={StorageDetail} />
				<Route path="/transfer-log/:cb_id" component={TransferLog} />
				<Route path="/mass-comm/list" component={ListMass} />
				<Route path="/mass-comm/create" component={CreateMass} />
				<Route path="/mass-comm/view/:id" component={ViewMass} />
				<Route path="/mass-comm/use/:id" component={UseMail} />
				<Route path="/mass-comm/edit/:id" component={EditMass} />

				<Route path="/admin/list" component={ListAdmin} />
				<Route path="/admin/create" component={CreateAdmin} />
				<Route path="/admin/edit/:id" component={EditAdmin} />
				<Route path="/department/list" component={ListDepartment} />
				<Route path="/report-overview" component={Report} />
				<Route path="/agent/list" component={Agent} />
				<Route path="/agent/invite" component={InviteAgent} />
				<Route path="/agent/edit/:id" component={EditAgent} />
				<Route path="/quiz/list" component={ListExam} />
				<Route path="/quiz/create" component={CreateExam} />
				<Route path="/quiz/edit/:id" component={EditExam} />
				<Route path="/quiz/preview/:id" component={PreviewQuiz} />

				<Route path="/plan/list" component={ListPlan} />
				<Route path="/plan/create" component={CreatePlan} />
				<Route path="/plan/edit/:id" component={EditPlan} />
				<Route path="/plan/feature" component={ListFeature} />

				<Route path="/storage/list" component={ListStorage} />
				<Route path="/storage/create" component={CreateItem} />
				<Route path="/storage/edit/:id" component={EditItem} />

				<Route path="/referral/list" component={ReferralList} />

				{/* <Route path="/ads/list" component={ListAds} /> */}
				{/* <Route path="/ads/create" component={CreateAds} /> */}
				{/* <Route path="/ads/edit/:id" component={EditAds} /> */}
				<Route path="/sales-report" component={SaleReport} />
				<Route path="/course-builder/list" component={ListBuilder} />
				<Route path="/course-builder/create" component={CreateBuilder} />
				<Route path="/course-builder/edit/:id" component={UpdateBuilder} />
				<Route path="/course-builder/preview/:id" component={PreviewBuilder} />
				<Route path="/marker/list" component={ListMarker} />
				<Route path="/marker/create" component={CreateMarker} />
				<Route path="/marker/edit/:id" component={EditMarker} />
				<Route path="/question-bank/list" component={ListQuestionBank} />
				<Route path="/question-bank/create" component={CreateQuestion} />
				<Route path="/question-bank/edit/:id" component={EditQuestion} />
				<Route path="/role/list" component={ListRole} />
				<Route path="/role/edit/:id" component={EditRole} />
				<Route path="/role/create" component={CreateRole} />
				<Route path="/setting" component={Setting} />
			</Switch>
		</Suspense>
	);
}
